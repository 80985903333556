/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './banner.scss';
import { useWeb3React } from '@web3-react/core'
import useApprove from '../../../hooks/useApprove';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Banner = () => {

    const { account } = useWeb3React();
    const { Approve } = useApprove()
    const [minted, setMinted] = useState(false);
    const [inProcess, setInProcess] = useState(false);

    const appoved = async (e) => {
        if (account) {
            try {
                e.preventDefault();
                setInProcess(true)
                const tx = await Approve()
                if (tx) {
                    toast.success('Successfully Approved', {
                        position: "top-right",
                        autoClose: 2000,
                    });
                    setMinted(true)
                }
                setInProcess(false)
                window.$("#exampleModalLong2").modal('hide');

            }
            catch (err) {
                console.log(err)
                toast.error(err.message, {
                    position: "top-right",
                    autoClose: 2000,
                });
                setInProcess(false)
            }
        }
        else {
            window.$("#exampleModalLong2").modal('hide');
            toast.error('Please Connect to wallet', {
                position: "top-right",
                autoClose: 2000,
            });
        }
    }


    return (
        <>
            {/* <section className="main-banner ptb" id="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 my-auto order-lg-0 order-1">
                            <div className="inner-content">
                              
                                <h2 className="white text-uppercase">Duis <span className=' rainbow  for-heading'>aute irure dolor</span> in repre in voluptate</h2>
                                <p className="ptb20 grey">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae.</p>
                                <ul className="list-inline ptb20">
                                    <li className="list-inline-item">
                                        <a className="btn-common" href="" target="_blank">Mint Now </a>
                                    </li>
                                    <li className="list-inline-item mt-sm-0 mt-5">
                                        <a className="btn-common2 bg-transparent white" target="_blank">View on OpenSea <img className='ml-2' src="/hash-max-assets/header/Arrow1.png" alt="" /></a>
                                    </li>
                                </ul>
                            
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="inner-content text-center">
                                <div className="inner-img">
                                <img src="/hash-max-assets/header/header-gif.gif" className="img-fluid" alt="" />

                            </div>
                            
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}
            <section className="main-banner">
                <div className="container">
                    <img src="\banner-img.gif" alt="img" className='img-fluid' />
                </div>
            </section>
        </>
    )
}

export default Banner;
