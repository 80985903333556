import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import './about.scss';
const About = () => {
    const owl_option = {
        nav: true,
        dots: true,
        dotsEach: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        navText: ["<p className='backword'><</p>", "<p className='forward'>></p>"],
        responsive: {
            0: {
                items: 2,
                stagePadding: -100,
                margin: 10,
            },
            400: {
                items: 2,
                stagePadding: -100,
                margin: 15,
            },
            600: {
                items: 2,
                stagePadding: -100,
                margin: 10,
            },
            700: {
                items: 2,
                stagePadding: 10,
                margin: 10,

            },
            1000: {
                items: 3,
                stagePadding: 10,
                margin: 10,

            },
            1200: {
                items: 3,
                stagePadding: 10,
                margin: 10,

            }
        },
    };
    return (
        <>
            <section className="main-about ptb" id="aboutus">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="inner-img text-center">
                                <h2 className="white  text-uppercase  ">Key elements of </h2>
                                <h2 className="rainbow ">  Xenoverse</h2>
                                <p className="grey ptb20">Our mission is to gather and create a community of like-minded NFT collectors and artists through an all-in-one platform.
                                    .</p>
                                {/* <p className="grey">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium <br /> doloremque laudantium, totam rem aperiam eaque ipsa.</p> */}

                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-12 p-0">
                            <div className="first-second">

                                {/* <OwlCarousel className="slider-items owl-carousel ltf-owl" autoplaySpeed={3000}  {...owl_option}> */}
                                <div className="main-card text-left">
                                    <div className="">
                                <div className="main-tile text-left">
                                    <img src="hash-max-assets\features\feature-icon-one.gif" className="img-fluid" alt="" />
                                    <h4 className="white ptb20 text-uppercase">NFT
                                    </h4>
                                    <p className="grey">Total of 8888 units of uniquely designed Xenoroids, including 50 super Xenoroids. Each has their own specific roles to participate in the libertized civilization to keep the balance, harmony, and equality of Xenoverse.
                                    </p>
                                </div>
                                </div>
                                </div>

                                <div className="main-card text-left">
                                    <div className="">
                                        <div className="main-tile text-left">
                                            <img src="hash-max-assets\features\feature-icon-two.gif" className="img-fluid" alt="" />
                                            <h4 className="white ptb20 text-uppercase">Token</h4>
                                            <p className="grey">XVC is a utility and governance ERC-20 token using Polygon Network, created for usage within the Xenoverse platform. creating a strong incentive for a decentralized and healthy tokenomic system.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="main-card text-left">
                                    <div className="">
                                        <div className="main-tile text-left">
                                            <img src="hash-max-assets\features\feature-icon-three.gif" className="img-fluid" alt="" />
                                            <h4 className="white ptb20 text-uppercase">
                                            Metaverse
                                            </h4>
                                            <p className="grey ">Xenoverse metaverse is bringing to you a virtual world with limitless opportunities and possibilities where you can explore and roam with your Xenoroids and use XVC token. Xenoverse proves to be a futuristic and fun loaded platform for virtual world explorers. </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="main-card text-left">
                                    <div className="">
                                        <div className="main-tile text-left">
                                            <img src="hash-max-assets\features\feature-icon-four.gif" className="img-fluid" alt="" />
                                            <h4 className="white ptb20 text-uppercase">Maximize Profit</h4>
                                            <p className="grey ">Xenoroid holders will get lifetime rewards from Xenoverse Artixen DAO auctions & Xen DAO dividends and lifetime membership and voting rights in Xen DAO through which they can govern the ecosystem through consensus.
                                                </p>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="item mt-2 bg-danger">
                                            <div className="main-card  text-left">
                                                <div className="icon">
                                                   sadadasd
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item mt-2">
                                            <div className="main-card text-left">
                                                <div className="icon">
                                                   sadadasd
                                                </div>
                                            </div>
                                        </div> */}

                                {/* </OwlCarousel> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;
