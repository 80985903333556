import React from 'react';
import Footer from '../landing/footer/Footer';
import { Link } from 'react-router-dom';
import './collection.scss';
const Collection = () => {
    return (
        <>
            <section className="main-collection ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className=" ptb text-center">
                                <h1 className="rainbow">XENOROID <span className='white'>COLLECTION</span></h1>
                                <p className='grey ptb20'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </div>
                        </div>
                    </div>

                    <div className=' inner-tile text-center '>
                        <div class="row">
                            <div className='col-sm-3'>
                                <p className='grey ptb20'>XENO token in Wallet
                                </p>
                                <h3 className='white'>  12,000 XENO</h3>

                            </div>
                            <div className='col-sm-3'>
                                <p className='grey ptb20'>My Xenoroid
                                </p>
                                <h3 className='white'>  8</h3>


                            </div>
                            <div className='col-sm-3'>
                                <p className='grey ptb20'>Avaiable for Mutation
                                </p>
                                <h3 className='white'>  1</h3>


                            </div>
                            <div className='col-sm-3'>
                                <div className='inner-btn mt-40'>
                                    <Link to="/mutate" className='btn-common'>MUTATE</Link>
                                </div>


                            </div>

                        </div>
                    </div>

                    <div className="row ptb">

                        <div className="col-sm-3">
                            <div className="card-hover">
                                    <img src="hash-max-assets\community\image-one.png" className="img-fluid" alt="" />
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                        <h4 className='rainbow'>#014</h4>
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="card-hover">
                                    <img src="hash-max-assets\community\image-one.png" className="img-fluid" alt="" />
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                        <h4 className='rainbow'>#014</h4>
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="card-hover">
                                    <img src="hash-max-assets\community\image-one.png" className="img-fluid" alt="" />
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                        <h4 className='rainbow'>#014</h4>
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="card-hover">
                                    <img src="hash-max-assets\community\image-one.png" className="img-fluid" alt="" />
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                        <h4 className='rainbow'>#014</h4>
                                    </div>
                            </div>
                        </div>


                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}

export default Collection;
