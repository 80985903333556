import React from 'react';
import './community.scss';
import OwlCarousel from 'react-owl-carousel';
const Community = () => {
    const owl_option = {
        nav: true,
        dots: false,
        dotsEach: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        // navText: ["<i class='fa fa-long-arrow-left'></i>", "<i class='fa fa-long-arrow-right'></i>"],
        responsive: {
            0: {
                items: 2,
                stagePadding: -100,
                margin: 10,
            },
            400: {
                items: 2,
                stagePadding: -100,
                margin: 15,
            },
            600: {
                items: 2,
                stagePadding: -100,
                margin: 10,
            },
            700: {
                items: 3,
                stagePadding: 10,
                margin: 10,

            },
            1000: {
                items: 5,
                stagePadding: 10,
                margin: 10,

            },
            1200: {
                items: 5,
                stagePadding: 10,
                margin: 10,

            }
        },
    };
    return (
        <>
            <section className="community ptb" id="community">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-sm-12 p-0">
                            <div className="first-second">

                                <OwlCarousel className="slider-items owl-carousel ltf-owl" autoplaySpeed={3000}  {...owl_option}>
                                    <div className="item mt-2">
                                        <div className="">
                                            <div className="main-tile text-center">
                                                <img src="\Male\3815.png" className="img-fluid" alt="" />
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                    <h4 className='rainbow'>#3815</h4>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\Male\4071.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#4071</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\Male\4199.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#4199</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\Male\231.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#231</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\Male\3126.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#3126</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\Male\41.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#41</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\Male\3666.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#3666</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\Male\4333.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#4333</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\Male\1829.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#1829</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\Male\3619.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#3619</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="item mt-2 bg-danger">
            <div className="main-card  text-center">
                <div className="icon">
                   sadadasd
                </div>
            </div>
        </div>
        <div className="item mt-2">
            <div className="main-card text-center">
                <div className="icon">
                   sadadasd
                </div>
            </div>
        </div> */}

                                </OwlCarousel>
                            </div>
                            <div className="first-second">

                                <OwlCarousel className="slider-items owl-carousel ltf-owl" autoplaySpeed={3000}  {...owl_option}>
                                    <div className="item mt-2">
                                        <div className="">
                                            <div className="main-tile text-center">
                                                <img src="\female\3455.png" className="img-fluid" alt="" />
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                    <h4 className='rainbow'>#3455</h4>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\female\539.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#539</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\female\56.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#56</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\female\983.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#983</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\female\1183.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#1183</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\female\991.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#991</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\female\599.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#599</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\female\928.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#928</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\female\1133.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#1133</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item mt-2">
                                        <div className="main-card text-center">
                                            <div className="">
                                                <div className="main-tile text-center">
                                                    <img src="\female\4323.png" className="img-fluid" alt="" />
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h4 className=" rainbow ptb20">XENOVERSE</h4>
                                                        <h4 className='rainbow'>#4323</h4>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="item mt-2 bg-danger">
            <div className="main-card  text-center">
                <div className="icon">
                   sadadasd
                </div>
            </div>
        </div>
        <div className="item mt-2">
            <div className="main-card text-center">
                <div className="icon">
                   sadadasd
                </div>
            </div>
        </div> */}

                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Community;
