import React from 'react';
import Footer from '../landing/footer/Footer';
import './mutate.scss';
const Mutate = () => {
    return (
        <>
            <section className="main-mutate ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className=" ptb text-center">
                                <h1 className="rainbow">MUTATE <span className='white'>XENOROID</span></h1>
                                <p className='grey ptb20'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-sm-6 Card  display flex justify-content space-between">
                            <ul class="list-group ptb20 ">
                                <li class="list-group-item ">
                                    <h5 className='white'>Change Head</h5>
                                    <p className='grey'>20 XENO </p>
                                </li>
                            </ul>
                            <ul class="list-group ">
                                <li class="list-group-item">
                                    <h5 className='white'>Change EYES</h5>
                                    <p className='grey'>20 XENO </p>
                                </li>
                            </ul>
                            <ul class="list-group ptb20">
                                <li class="list-group-item">
                                    <h5 className='white'>Change Mouth</h5>
                                    <p className='grey'>20 XENO </p>
                                </li>
                            </ul>
                            <ul class="list-group ">
                                <li class="list-group-item">
                                    <h5 className='white'>Change Body</h5>
                                    <p className='grey'>20 XENO </p>
                                </li>
                            </ul>
                            <ul class="list-group ptb20">
                                <li class="list-group-item">
                                    <h5 className='white'>Change Clothes</h5>
                                    <p className='grey'>20 XENO </p>
                                </li>
                            </ul>
                            <ul class="list-group pb20">
                                <li class="list-group-item">
                                    <h5 className='white'>Change Background</h5>
                                    <p className='grey'>20 XENO </p>
                                </li>
                            </ul>
                            <button class="btn-common w-100" data-toggle="modal" data-target="#exampleModalLong1">DONE</button>





                        </div>

                        <div className="col-sm-6">
                            <div className=" text-center">
                                <img src="\hash-max-assets\community\banner-image.png" className="img-fluid" alt="" />
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <Footer />
            <div
                className="modal fade"
                id="exampleModalLong1"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-12 p-0">
                                    <button
                                        type="button"
                                        className="close pt-1"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                   
                                    <div className="pop-content">
                                        <div className="d-flex justify-content-center">
                                            {/* <img src={Success} alt="" /> */}
                                        </div>
                                        <p className="mt-2 grey text-center px-3 pt-3">
                                        <img src="\hash-max-assets\community\success.png" className="img-fluid" alt="" />
                             
                                        <h4 className=" ptb20 white text-center">VOLUPTATEM ACCUSANTIUM</h4>
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                            accusantium doloremque
                                        </p>
                                        <ul className="list-inline btn-div text-center px-5 pt-4">
                                            <li className="list-inline-item w-100">
                                                <button className="btn-common w-100">Claim</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ClaimModal cancel={cancel} /> */}
            </div>
        </>
    )
}

export default Mutate;
