import { useState } from 'react';
import './faqs.scss';
const Faqs = () => {
    return (
        <>
            <section className="main-faqs ptb" id="faqs">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="inner-content  ptb20">
                                <p className="grey text-md-left text-center">FAQ</p>
                                <h2 className="white">GET THE <span className="rainbow"> FACTS STRAIGHT </span>  </h2>
                                {/* <p className=" ptb20">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium <br/> doloremque laudantium, totam rem aperiam eaque ipsa.</p> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 inner-upper">
                            <div className="inner-side ptb20">
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link d-flex justify-content-between align-items-center " data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h5 className="">Xenoverse, what is it really?</h5>
                                                    <p className='font-weight-bold'>+</p>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body">
                                            We are a community of like-minded NFT collectors and artists through an all-in-one platform where they can easily interact, collect art and also get rewards. Long-term goal of our community is to build a metaverse where NFTs and Coins participate in the world. .
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h5 className="">What is the Xenoroid NFT?</h5>
                                                    <p className='font-weight-bold'>+</p>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div className="card-body">
                                            NFTs are a special type of digital asset that is not interchangeable with any other digital asset and their uniqueness can be proved. Since they cannot be interchanged, that’s why they’re called non-fungible. .

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h5 className="">How to Buy Xenorid NFT?</h5>
                                                    <p className='font-weight-bold'>+</p>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div className="card-body">
                                            It will be available on OpenSea, also through our whitelist..
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingfour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                                    <h5 className="">How Many NFT Can I Buy?</h5>
                                                    <p className='font-weight-bold'>+</p>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                            <div className="card-body">
                                            5 NFTs will be minted in 1 transaction for presale, and the number doubles for public sale i.e 10 NFTs..</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingfive">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefour">
                                                    <h5 className="">How To Get On The Whitelist?</h5>
                                                    <p className='font-weight-bold'>+</p>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapsefive" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                            <div className="card-body">
                                            The presale will have 1500 Xenoroids at $150 or 250 matic per Xenoroid and the Xenoverse community can participate in the presale through Whitelisting only. In order to be in the whitelist, please join our discord channel and apply accordingly.</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='main-tile'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='inner-content  text-center'>
                                    <img src="/hash-max-assets/community/community-gif.gif" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className='col-sm-6 my-auto pl-md-5'>
                                <div className='inner-content text-sm-left text-center mt-sm-0 mt-4'>
                                    <h6 className='grey text-uppercase'>Xenoverse Community</h6>
                                    <h2 className="white">JOIN THE</h2>
                                    <h2 className='rainbow'> Xenoverse <br></br> Community</h2>
                                    <button className='btn-common ptb20'><img src="/hash-max-assets/community/discord.svg" className='img-fluid mr-2' alt="" /> Join the Discord</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Faqs;
