import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './navbar.scss';
import { useWeb3React } from '@web3-react/core'
import { HashLink } from 'react-router-hash-link';
import useAuth from '../../../hooks/useAuth';
// import $ from "jquery";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
//  Create WalletConnect Provider
// const provider = new WalletConnectProvider({
//     infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
// });
const Navbar = () => {

    const { account } = useWeb3React();
    // console.log("account",account)
    const { login, logout } = useAuth();
    const connectwallet = () => {
        window.$("#exampleModalLong").modal('show');
    }
    const connectMetamask = () => {
        localStorage.setItem("connectorId", "injected")
        if (account) {
            logout()
        } else {
            login("injected")
        }
    }
    const trustWallet = async () => {
        localStorage.setItem("connectorId", "walletconnect")
        if (account) {
            logout()
        } else {
            login("walletconnect")
        }
    }

    const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

    return (
        <section className="main-navbar">
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="/">
                        <img src="/hash-max-assets/header/logo.svg" className="img-fluid" alt="" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <div class="style-bar"></div>
                        <div class="style-bar"></div>
                        <div class="style-bar"></div>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item navso">
                            <HashLink className={isActive("#banner") ? "nav-link active-link" : "nav-link"} to="#banner">
                                HOME
                            </HashLink>
                            </li>
                            <li className="nav-item">
                            <HashLink className={isActive("#aboutus") ? "nav-link my-sm-0 my-2 active-link" : "nav-link my-sm-0 my-2"} to="#aboutus">
                            ABOUT
                             
                            </HashLink>
                            </li>
                            <li className="nav-item">
                            <HashLink className={isActive("#whyus") ? "nav-link active-link" : "nav-link"} to="#whyus">
                            FEATURES
                            </HashLink>
                            </li>
                            <li className="nav-item">
                            <HashLink className={isActive("#faqs") ? "nav-link my-sm-0 my-2 active-link" : "nav-link my-sm-0 my-2"} to="#faqs">
                                FAQS
                            </HashLink>
                            </li>
                            <li className="nav-item">
                            <HashLink className={isActive("#community") ? "nav-link active-link" : "nav-link"} to="#community">
                            COMMUNITY
                            </HashLink>
                            </li>
                            <li className="nav-item">
                            <Link className="nav-link" to="/collection">
                            COLLECTION
                            </Link>
                            </li>
                        </ul>
                        <form className="form-inline my-2 my-lg-0">
                         
                           {/* <a href="https://xenoverse-nft-staking.netlify.app" className='btn-common' target="_blank">stake App</a> */}
                           
                            {/* <HashLink className="nav-link" to="#roadmap">
                                ROADMAP
                            </HashLink> */}
                            
                            
                        </form>
                    </div>
                </nav>
            </div>
            <img src="/hash-max-assets/header/vectorLeft.svg"  className='vectorLeft img-fluid d-sm-block d-none' alt="" />
            <img src="/hash-max-assets/header/vectorRight.svg"  className='vectorRight img-fluid d-sm-block d-none' alt="" />
            <div className="brdr"></div>
        </section>
    )
}

export default Navbar;
