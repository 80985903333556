import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Navbar from './components/landing/header/Navbar.js';
import Audit from "./components/audit/Audit.js";
import Support from "./components/support/Support.js";
import useEagerConnect from './hooks/useEagerConnect';
import Collection from './components/collection/Collection';
import Mutate from './components/mutate/Mutate';

function App() {
  useEagerConnect()
  return (
    <>
          <Router>
            <Navbar/>
            {/* <Audit/> */}
            <Switch>
              <Route exact path='/' component={Landing} />
              <Route exact path='/audit' component={Audit} />
              <Route exact path='/support' component={Support} />
              <Route exact path='/collection' component={Collection} />
              <Route exact path='/mutate' component={Mutate} />
            </Switch>
          </Router>
    </>
  );
}

export default App;
