/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './Xenoverse.scss';
import { useWeb3React } from '@web3-react/core'
import useApprove from '../../../hooks/useApprove';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Xenoverse = () => {

    const { account } = useWeb3React();
    const { Approve } = useApprove()
    const [minted, setMinted] = useState(false);
    const [inProcess, setInProcess] = useState(false);

    const appoved = async (e) => {
        if (account) {
            try {
                e.preventDefault();
                setInProcess(true)
                const tx = await Approve()
                if (tx) {
                    toast.success('Successfully Approved', {
                        position: "top-right",
                        autoClose: 2000,
                    });
                    setMinted(true)
                }
                setInProcess(false)
                window.$("#exampleModalLong2").modal('hide');

            }
            catch (err) {
                console.log(err)
                toast.error(err.message, {
                    position: "top-right",
                    autoClose: 2000,
                });
                setInProcess(false)
            }
        }
        else {
            window.$("#exampleModalLong2").modal('hide');
            toast.error('Please Connect to wallet', {
                position: "top-right",
                autoClose: 2000,
            });
        }
    }


    return (
        <>
            <section className="main-Xenoverse ptb" id="banner">
                <div className="container">
                    <div className='row'>
                        <div className='col-sm-12'>
                                <h2 className="white ptb20 text-uppercase">What is  <span className=' rainbow  for-heading'> Xenoverse?</span></h2>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-6">
                            <div className="inner-content text-center">
                              
                            <img src="/hash-max-assets/about-us/about-us-gif.gif" className="img-fluid" alt="" />
                            
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="inner-content ptb20">
                                <div className="inner-img">
                                <h4 className='white text-uppercase'>Xenoverse is an extensive cryptocurrency metaverse and NFT portal with all the things a crypto head would want to indulge in. </h4>
                                <p className='ptb20 grey mt-3'>From generative NFTs to LP staking, Xenoverse proves to be a further big step towards a futuristic virtual world with unique lives of Xenoroid NFTs, designs inspired with high-end quality, AI androids, and anime drawing. With XVC (Xenoverse Coin), the Xenoverse native token, you will be able to experience DeFi features such as LP staking and utility usages inside Xenoverse.  </p>
                                {/* <p className='grey'>
                                    
                                We’ll be heading on an extensive roadmap ahead of us that will include an ERC-20 token and a well-established staking platform that allows the Xenoverse community to stake not just the NFTs, but Xenoverse Coin (XVC) and LP tokens as well. 
                                    

                                </p> */}
                            </div>
                            
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Xenoverse;
